import { Box, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import NavigationArrows from '../Toggles/NavigationArrows';

const useStyles = makeStyles((theme) => ({
    dropdownInput:{
        width: '100%',
        fontSize: theme.typography.body1,
        paddingLeft:'5px',
        height: '32px',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,        
    },
}));

const Pagination = ({
    perPageVal=10,
    onPerPageChange=null,
    itemsOnCurrentPage=0,
    currentOffset=0,
    totalItems=0,
    perPageOptions = [10, 20, 50, 100],
    handlePageBack=null,
    handlePageForward=null,
}) => {
    const classes = useStyles()

    const perPageValue = perPageOptions.includes(perPageVal) ? perPageVal : perPageOptions[0]

    return (
        <Box  display='flex' className={classes.paginationContainer}>
            <Typography variant='body2' style={{padding: '5px 10px', whiteSpace:'nowrap'}}>
                Show
            </Typography>
            <select
                data-testid='perPageSelect'
                className={classes.dropdownInput}
                value={perPageValue}
                onChange={elem => onPerPageChange(+elem.target.value)}
            >
            {perPageOptions.map((option, index) => {
                return (<option data-testid={`perPageOption-${index}`} key={index} value={option}>{option}</option>)
            })}
            </select>
            <NavigationArrows 
                itemsOnCurrentPage={itemsOnCurrentPage}
                currentOffset={currentOffset}
                onBack={handlePageBack} 
                onForward={handlePageForward} 
                totalItems={totalItems}     
            />
        </Box>
    )
}

Pagination.propTypes = {
    itemsOnCurrentPage: PropTypes.number,
    currentOffset: PropTypes.number,
    totalItems: PropTypes.number,
    handlePerPageChange: PropTypes.func,
    handlePageBack: PropTypes.func,
    handlePageForward: PropTypes.func,
    perPageVal: PropTypes.number,
    perPageOptions: PropTypes.array,
    pageBackDisabled: PropTypes.bool,
    pageForwardDisabled: PropTypes.bool,
    style: PropTypes.object
}

export default Pagination
