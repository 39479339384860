import Axios from "axios";
import { getRegionalizedUrl, MANAGE_API } from "@mc/common/lib/url-utils";
import { encodeUrlParams } from "@mc/common/lib/url-params";


export const getLatestActivity = (searchTerm, actionType, offset, pageSize, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/getLogActivity',
        data: encodeUrlParams({
            search: searchTerm,
            actions: actionType,
            offset: offset,
            pageSize: pageSize,
            ini_user: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}
