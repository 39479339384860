import React from 'react'
import PageHeader from '@mc/common/components/Page/PageHeader'
import { Box, CircularProgress, Divider, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { getLatestActivity } from '../api/activity';
import { AuthenticationContext } from '../App/AuthenticationContext';
import { 
    activityLogParamReducer, 
    ActivityLogReducerActionTypes, 
    DISPLAYED_ACTIVITY_NAMES, 
    initialActivityLogParamState 
} from './ActivityLogReducer';
import ActivityLogToolBar from './ActivityLogToolBar';
import Pagination from '../../../common/components/Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
    summaryGrid: {
        flexGrow: 1,
    },
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },
    detailsCell: {
        maxWidth:300, 
        textOverflow: 'ellipsis', 
        overflow: 'hidden'
    },
    loadingOrEmptyResults: {
        padding: 20,
        margin: 'auto',
        textAlign: 'center',
        width: '50%',
    },
    headerCell:{
        fontWeight: 600
    },
    pagination: {
        float: 'right',
        padding: 20,
    },

}));

const colHeaders = [
    { displayName: 'User', key: 'email' },
    { displayName: 'Action', key: 'action' },
    { displayName: 'Details', key: 'details' },
    { displayName: 'Source IP', key: 'src_ip' },
    { displayName: 'Date & Time (UTC)', key: 'timestamp' },
]


const ActivityLog = () => {
    const classes = useStyles()
    const [authState,] = React.useContext(AuthenticationContext)
    const [activityParamState, activityParamDispatch] = React.useReducer(activityLogParamReducer, initialActivityLogParamState)
    const [totalItemCount, setTotalItemCount] = React.useState(0)
    const [rows, setRows] = React.useState([])
    const [loading, setLoading] = React.useState(false)


    const fetchActivities = () => {
        setLoading(true)
        getLatestActivity(
            activityParamState.searchTerm, 
            activityParamState.action, 
            activityParamState.offset, 
            activityParamState.pageSize,
            authState
            ).then(response => {
                setRows(response.data.results)
                setTotalItemCount(response.data.count)
                setLoading(false)
        })
    }

    React.useEffect(fetchActivities, [activityParamState])

    const handleChangeActionFilter = (value) => {
        activityParamDispatch({
            type: ActivityLogReducerActionTypes.UPDATE_ACTION_FILTER,
            payload: value
        })
    }

    const handleSearch = (searchTerm) => {
        activityParamDispatch({
            type: ActivityLogReducerActionTypes.UPDATE_SEARCH_TERM,
            payload: searchTerm
        })
    }

    const handleChangePerPage = (value) => {
        activityParamDispatch({
            type: ActivityLogReducerActionTypes.UPDATE_PAGE_SIZE,
            payload: value
        })
    }

    const handlePageForward = () => {
        activityParamDispatch({
            type: ActivityLogReducerActionTypes.UPDATE_OFFSET,
            payload: activityParamState.offset + activityParamState.pageSize
        })
    }

    const handlePageBack = () => {
        activityParamDispatch({
            type: ActivityLogReducerActionTypes.UPDATE_OFFSET,
            payload: activityParamState.offset - activityParamState.pageSize
        })
    }

    return (
        <>
            <PageHeader pageTitle="Activity Logs" subText="Activity logged for customer account" />
            <TableContainer component={Paper}>
                <ActivityLogToolBar
                    onSearch={handleSearch}
                    onChangeActivityFilter={handleChangeActionFilter}
                    itemsOnCurrentPage={rows.length}
                    totalItems={totalItemCount}
                    currentOffset={activityParamState.offset}
                    handlePerPageChange={handleChangePerPage}
                    handlePageBack={handlePageBack}
                    handlePageForward={handlePageForward}
                    perPageValue={activityParamState.pageSize}
                />            
                <Divider/>
                {loading && 
                    <Box className={classes.loadingOrEmptyResults}>
                        <CircularProgress color="secondary" />
                    </Box>
                }
                {!loading && rows.length === 0 &&
                    <Box className={classes.loadingOrEmptyResults}>
                        <Typography variant='h3' color='secondary'>
                            No Results
                        </Typography>
                    </Box>
                }
                {!loading && rows.length > 0 &&
                    <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {colHeaders.map((headCell) => (
                                <TableCell
                                    key={headCell.key}
                                    className={classes.headerCell}
                                >
                                    {headCell.displayName}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    key={`${index}-${row.id}`}
                                >
                                    <TableCell>
                                        {row.email}
                                    </TableCell>
                                    <TableCell>{DISPLAYED_ACTIVITY_NAMES[row.action] || 'None'}</TableCell>
                                    <TableCell className={classes.detailsCell}>{row.details}</TableCell>
                                    <TableCell>{row.src_ip}</TableCell>
                                    <TableCell>{row.timestamp}</TableCell>
                                </TableRow>
                            )
                        })}
                        </TableBody>
                    </Table>                          
                    <Box className={classes.pagination}>
                        <Pagination
                                itemsOnCurrentPage={rows.length}
                                currentOffset={activityParamState.offset}
                                totalItems={totalItemCount}
                                onPerPageChange={handleChangePerPage}
                                handlePageBack={handlePageBack}
                                handlePageForward={handlePageForward}
                                perPageVal={activityParamState.pageSize}
                        />
                    </Box>
                    </>
                }
        </TableContainer>
        </>
    )
}

export default ActivityLog
