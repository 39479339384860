import React from 'react'
import ActivityLog from '../ActivityLog/ActivityLog'
import ProtectedViewLayout from '../App/ProtectedViewLayout';


const ActivityLogPage = ({ location }) => {
    return (
        <ProtectedViewLayout location={location}>
            <ActivityLog />
        </ProtectedViewLayout>
    );
}

export default ActivityLogPage