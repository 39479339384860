import {makeStyles, Toolbar, Grid, Box } from '@material-ui/core';
import Pagination from '@mc/common/components/Pagination/Pagination';
import SearchBar from 'material-ui-search-bar';
import React from 'react'
import { ALL_ACTIVITIES, AVAILABLE_ACTIVITY_ACTION_FILTERS } from './ActivityLogReducer';

const useToolbarStyles = makeStyles((theme) => ({
    searchBar: {
        width: 350,
        height: 32,
        borderRadius: '6px',
        border: `1px solid ${theme.colors[2]}`,
        boxShadow: 'none',
    },
    toolBar: {
        padding: 20,
    },
    inputLabelText: {
        fontWeight: 600,
        paddingBottom: 10,
        textAlign: 'left'
    },
    dropdownInput:{
        width: 350,
        height: 32,
        fontSize: 15,
        lineHeight: 23,
        paddingLeft:'5px',
        borderRadius: '6px',
        border: `1px solid ${theme.colors[2]}`,
    },
    pagination: {
        float: 'right',
        paddingTop: 30,
        paddingBottom: 20,
    },
}));
  
const ActivityLogToolBar = ({
    itemsOnCurrentPage=0,
    totalItems=0,
    currentOffset=0,
    handlePerPageChange=null,
    handlePageBack=null,
    handlePageForward=null,
    perPageValue=10,
    onSearch=null,
    onChangeActivityFilter=null,
}) => {
    const classes = useToolbarStyles();

    const [localSearchTerm, setLocalSearchTerm] = React.useState('')
    const [actionFilterSelect, setActionFilterSelect] = React.useState(AVAILABLE_ACTIVITY_ACTION_FILTERS[ALL_ACTIVITIES])

    const handleChangeActionFilter = (val) => {
        let newFilter = AVAILABLE_ACTIVITY_ACTION_FILTERS[val] || 'all'
        setActionFilterSelect(newFilter)
        if(onChangeActivityFilter){
            onChangeActivityFilter(newFilter.value)
        }
    }
    
    const clearSearch = () => {
        setLocalSearchTerm('')
        if(onSearch){
            onSearch('')
        }
    }

    const executeSearch = () =>{
        if(onSearch){
            onSearch(localSearchTerm)
        }
    }

    return (
      <Toolbar className={classes.toolBar}>
          <Grid justifyContent="space-between"
                container 
                spacing={2}
            >
            <Grid item md={12} lg={4}>
                <SearchBar
                    data-testid='activityToolbarSearch'
                    value={localSearchTerm}
                    onChange={(newValue) => setLocalSearchTerm(newValue)}
                    onRequestSearch={executeSearch}
                    onCancelSearch={clearSearch}
                    className={classes.searchBar}
                />
            </Grid>
            <Grid item md={12} lg={4}>
                <Box style={{float:'right'}}>
                    <select
                        data-testid='activitySelect'
                        className={classes.dropdownInput}
                        value={actionFilterSelect.value}
                        onChange={elem => handleChangeActionFilter(elem.target.value)}
                    >
                        {Object.entries(AVAILABLE_ACTIVITY_ACTION_FILTERS).map((dropdownItem, index) => (
                            <option className={classes.dropdownItem} data-testid={`activitySelectOption`} key={index} value={dropdownItem[1].value}>{dropdownItem[1].label}</option>
                        ))}
                    </select>
                </Box>
            </Grid>
            <Grid item xl={12}>
                <Box className={classes.pagination}>
                    <Pagination
                            itemsOnCurrentPage={itemsOnCurrentPage}
                            currentOffset={currentOffset}
                            totalItems={totalItems}
                            onPerPageChange={handlePerPageChange}
                            handlePageBack={handlePageBack}
                            handlePageForward={handlePageForward}
                            perPageVal={perPageValue}
                    />
                </Box>
            </Grid>
          </Grid>
      </Toolbar>
    )
  }

  export default ActivityLogToolBar