import React from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    paginationButton:{
        maxWidth: 32,
        minWidth: 32,
        maxHeight: 32,
        minHeight: 32,
        backgroundColor: theme.palette.secondary.light
    },
}))

const NavigationArrows = ({
     totalItems=0,
     onBack=null, 
     onForward=null, 
     itemsOnCurrentPage=0,
     currentOffset=0,
     disableItemString=false,
     style={} 
}) => {
    const classes = useStyles()

    const handleForwardClick = () => {
        if(onForward) onForward()
    }

    const handleBackClick = () => {
        if(onBack) onBack()
    }


    let itemsString = itemsOnCurrentPage === 0 || totalItems === 0 ? `0 - 0` 
        : `${currentOffset+1} - ${currentOffset + itemsOnCurrentPage}`
    
    if(disableItemString){
        itemsString = `${currentOffset + itemsOnCurrentPage}`
    }
    
    const backDisabled = currentOffset === 0 || totalItems === 0
    const forwardDisabled = totalItems === 0 || itemsOnCurrentPage + currentOffset + 1 > totalItems
    return (
        <Box display='flex' style={style}>
            <Typography data-testid="itemCountText" variant='body2' style={{padding: '5px 10px', whiteSpace:'nowrap'}}>
                <b>{itemsString}</b> of {totalItems}
            </Typography>
            <Button  data-testid="backButton" disabled={backDisabled} onClick={handleBackClick} variant='contained' style={{borderTopRightRadius:0, borderBottomRightRadius:0}} className={classes.paginationButton}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Button>
            <Button  data-testid="forwardButton" disabled={forwardDisabled} onClick={handleForwardClick} variant='contained' style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} className={classes.paginationButton}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
        </Box>
    )
}

NavigationArrows.propTypes = {
    handleBack : PropTypes.func, 
    handleForward: PropTypes.func, 
    itemsString: PropTypes.string, 
    totalItems: PropTypes.number,
    forwardDisabled: PropTypes.bool,
    backDisabled: PropTypes.bool, 
    itemsOnCurrentPage: PropTypes.number,
    currentOffset: PropTypes.number,
    disableItemString: PropTypes.bool,
    style: PropTypes.object
}

export default NavigationArrows