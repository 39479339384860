export const ALL_ACTIVITIES = 'all'
export const GROUPED_LOGINS = 'grouped_login'

export const ACTIVITIES = {
    UPDATE_GRAPH_RULE: 'update_graph_rule',
    UPDATE_CODEBREAKER_SETTINGS: 'update_codebreaker_settings',
    CODEBREAKER_WHITELIST_DOMAIN: 'codebreaker_whitelist_domain',
    CODEBREAKER_WHITELIST_DOMAIN_DELETE: 'codebreaker_whitelist_domain_delete',
    CSV_EXPORT_REQUEST: 'csv_export_request',
    GET_CSV_FILE_DOWNLOAD: 'get_csv_file_download',
    EMAIL_DIGEST_SUBSCRIBE: 'email_digest_subscribe',
    SEARCH: 'search',
    PASSWORD_LOGIN: 'login',
    LOGINS: 'grouped_login',
    ADMIN_CREATION:  'admin_creation',
    ADMIN_DELETED: 'admin_deleted',
    PASSWORD_RESET: 'password_reset',
    ADMIN_PASSWORD_RESET: 'admin_password_reset',
    PERMITTED_SENDERS: 'permitted_senders',
    EMAIL_SEARCH: 'email_search',
    DELETE_CSV_EXPORT: 'delete_csv_export',
    INVITE_ADMIN: 'invite_admin',
    ADD_TRACK_SETTINGS: 'add_track_settings',
    UPDATE_SILENCER_SETTINGS: 'update_silencer_settings',
    EMAIL_DIGEST_UNSUBSCRIBE: 'email_digest_unsubscribe',
    REMOVE_TRACK_SETTINGS: 'remove_track_settings',
    ENABLE_2FA: 'enable_2fa',
    ADD_MOBILE_NUMBER: 'add_mobile_number',
    VERIFY_CODE: 'verify_code',
    RESET_PASSWORD: 'reset_password',
    RESET_PASSWORD_EMAIL: 'reset_password_email',
    ACTIVATE_ACCOUNT: 'activate_account',
    CODEBREAKER_WHITELIST_TRUSTED_RELAY: 'codebreaker_whitelist_trusted_relay',
    CODEBREAKER_REPORT_ALERT: 'codebreaker_report_alert',
    SSO_LOGIN: 'sso_login',
    SAML_LOGIN: 'saml_login',
    IMPERSONATED_SAML_LOGIN: 'impersonated_saml_login',
    SAML_USER_PROVISIONED: 'saml_user_provisioned',
    WHITELIST_MODIFICATIONS: 'whitelist_modifications',
}

export const DISPLAYED_ACTIVITY_NAMES = {
    [ACTIVITIES.UPDATE_GRAPH_RULE]: 'Update Rule',
    [ACTIVITIES.UPDATE_CODEBREAKER_SETTINGS]: 'Update Banner Setting',
    [ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN]: 'Add Safelist Domain',
    [ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN_DELETE]: 'Delete Safelist Domain',
    [ACTIVITIES.CSV_EXPORT_REQUEST]: 'CSV Export',
    [ACTIVITIES.GET_CSV_FILE_DOWNLOAD]: 'Download CSV Export',
    [ACTIVITIES.EMAIL_DIGEST_SUBSCRIBE]: 'Subscribe to Digest',
    [ACTIVITIES.SEARCH]: 'Search',
    [ACTIVITIES.PASSWORD_LOGIN]: 'Password Login',
    [ACTIVITIES.ADMIN_CREATION]:  'Administrator Creation',
    [ACTIVITIES.ADMIN_DELETED]: 'Administrator Deletion',
    [ACTIVITIES.PASSWORD_RESET]: 'Password Reset',
    [ACTIVITIES.ADMIN_PASSWORD_RESET]: 'Administrator Password Reset',
    [ACTIVITIES.PERMITTED_SENDERS]: 'Update Permited Senders List',
    [ACTIVITIES.EMAIL_SEARCH]: 'Email Search',
    [ACTIVITIES.DELETE_CSV_EXPORT]: 'Delete CSV Export',
    [ACTIVITIES.INVITE_ADMIN]: 'Send Administrator Invitation',
    [ACTIVITIES.ADD_TRACK_SETTINGS]: 'Add Track Setting',
    [ACTIVITIES.UPDATE_SILENCER_SETTINGS]: 'Update Tracker Settings',
    [ACTIVITIES.EMAIL_DIGEST_UNSUBSCRIBE]: 'Unsubscribe to Digest',
    [ACTIVITIES.REMOVE_TRACK_SETTINGS]: 'Remove Track Setting',
    [ACTIVITIES.ENABLE_2FA]: 'Enable Two-Factor Authentication',
    [ACTIVITIES.ADD_MOBILE_NUMBER]: 'Add Mobile Number',
    [ACTIVITIES.VERIFY_CODE]: 'Verify Code',
    [ACTIVITIES.RESET_PASSWORD]: 'Password Reset',
    [ACTIVITIES.RESET_PASSWORD_EMAIL]: 'Password Reset Email',
    [ACTIVITIES.ACTIVATE_ACCOUNT]: 'Activate Account',
    [ACTIVITIES.CODEBREAKER_WHITELIST_TRUSTED_RELAY]: 'Update Safelist Trusted Relay',
    [ACTIVITIES.CODEBREAKER_REPORT_ALERT]: 'Dynamic Banner Report Alert',
    [ACTIVITIES.SSO_LOGIN]: 'SSO Login',
    [ACTIVITIES.SAML_LOGIN]: 'SSO Login',
    [ACTIVITIES.IMPERSONATED_SAML_LOGIN]: 'Impersonated SSO Login',
    [ACTIVITIES.SAML_USER_PROVISIONED]: 'User Provisioning Through SSO',
    [ACTIVITIES.WHITELIST_MODIFICATIONS]: 'Safelist Modifications',
}

export const AVAILABLE_ACTIVITY_ACTION_FILTERS = {
    [ALL_ACTIVITIES]: {
        label: 'All Activities',
        value: ALL_ACTIVITIES,
    },
    [GROUPED_LOGINS]:{
        label: 'Logins',
        value: GROUPED_LOGINS
    },
    [ACTIVITIES.SEARCH]:{
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.SEARCH],
        value: ACTIVITIES.SEARCH
    },
    [ACTIVITIES.UPDATE_GRAPH_RULE]:{
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.UPDATE_GRAPH_RULE],
        value: ACTIVITIES.UPDATE_GRAPH_RULE
    },
    [ACTIVITIES.UPDATE_CODEBREAKER_SETTINGS]:{
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.UPDATE_CODEBREAKER_SETTINGS],
        value: ACTIVITIES.UPDATE_CODEBREAKER_SETTINGS
    },
    [ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN]:{
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN],
        value: ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN
    },
    [ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN_DELETE]:{
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN_DELETE],
        value: ACTIVITIES.CODEBREAKER_WHITELIST_DOMAIN_DELETE
    },
    [ACTIVITIES.CSV_EXPORT_REQUEST]: {
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.CSV_EXPORT_REQUEST],
        value: ACTIVITIES.CSV_EXPORT_REQUEST
    },
    [ACTIVITIES.GET_CSV_FILE_DOWNLOAD]: {
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.GET_CSV_FILE_DOWNLOAD],
        value: ACTIVITIES.GET_CSV_FILE_DOWNLOAD
    },
    [ACTIVITIES.EMAIL_DIGEST_SUBSCRIBE]: {
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.EMAIL_DIGEST_SUBSCRIBE],
        value: ACTIVITIES.EMAIL_DIGEST_SUBSCRIBE
    },
    [ACTIVITIES.EMAIL_DIGEST_UNSUBSCRIBE]: {
        label: DISPLAYED_ACTIVITY_NAMES[ACTIVITIES.EMAIL_DIGEST_UNSUBSCRIBE],
        value: ACTIVITIES.EMAIL_DIGEST_UNSUBSCRIBE
    },
}

export const initialActivityLogParamState = {
    searchTerm: '',
    action: AVAILABLE_ACTIVITY_ACTION_FILTERS[ALL_ACTIVITIES].value,
    offset: 0,
    pageSize: 10,
}

export const ActivityLogReducerActionTypes = {
    UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
    UPDATE_ACTION_FILTER: 'UPDATE_ACTION_FILTER',
    UPDATE_PAGE_SIZE: 'UPDATE_PAGE_SIZE',
    UPDATE_OFFSET: 'UPDATE_OFFSET',
}

export const activityLogParamReducer = (state, action) => {
    switch (action.type) {
        case ActivityLogReducerActionTypes.UPDATE_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
                offset: initialActivityLogParamState.offset
            }
        case ActivityLogReducerActionTypes.UPDATE_ACTION_FILTER:
            // Only allowed activities    
            if(Object.values(ACTIVITIES)
                .concat(ALL_ACTIVITIES, GROUPED_LOGINS)
                .includes(action.payload)){
                return {
                    ...state,
                    action: action.payload,
                    offset: initialActivityLogParamState.offset
                }
            }
            return initialActivityLogParamState

        case ActivityLogReducerActionTypes.UPDATE_PAGE_SIZE:
            if(action.payload >= 0) {
                return {
                    ...state,
                    pageSize: action.payload,
                    offset: initialActivityLogParamState.offset
                }
            }
            return initialActivityLogParamState
        case ActivityLogReducerActionTypes.UPDATE_OFFSET:
            if(action.payload >= 0) {
                return {
                    ...state,
                    offset: action.payload
                }
            }
            return initialActivityLogParamState
            
        default:
            return initialActivityLogParamState
    }
}
